const stopwatch = {
  pageName: {
    en: `Stopwatch`,
    th: `จับเวลา`,
  },
  title: {
    en: `Stopwatch`,
    th: `จับเวลา`,
  },
  laps: {
    en: `Lap`,
    th: `รอบ`,
  },
  button: {
    start: {
      en: `Start`,
      th: `เริ่ม`,
    },
    pause: {
      en: `Pause`,
      th: `หยุด`,
    },
    lab: {
      en: `Lap`,
      th: `รอบ`,
    },
    reset: {
      en: `Reset`,
      th: `รีเซ็ต`,
    },
  },
}

export default stopwatch
