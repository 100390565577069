import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import { Button } from "@mui/material"
import { FaStopwatch } from "react-icons/fa"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/section"
import useInterval from "../../functions/use-interval"
import getSiteColor from "../../functions/get-site-color"
import hexToRgbaColor from "../../functions/hex-to-rgba-color"
import stopwatch from "../../static/stopwatch"
import { setCurrentPage } from "../../states/slices/main-slice"
import { playSound } from "../../states/slices/sound-slice"

const Flex = styled.div`
  display: flex;
  width: 100%;

  &.center__center {
    align-items: center;
    justify-content: center;
  }

  &.center__flex-end {
    align-items: center;
    justify-content: flex-end;
  }

  &.center__space-between {
    align-items: center;
    justify-content: space-between;
  }
`

const ClockContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.time {
    display: flex;
    align-items: baseline;
    font-size: 10rem;
    margin: 0;

    &.smaller {
      font-size: 8rem;
    }

    span.second {
      font-size: 3rem;
    }
  }

  p.date {
    font-size: 2.5rem;
    margin-top: 1rem;
    white-space: nowrap;
  }

  @media (max-width: 991px) {
    div.time {
      font-size: 6rem;

      &.smaller {
        font-size: 4.8rem;
      }

      span.second {
        font-size: 2rem;
      }
    }

    p.date {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 400px) {
    div.time {
      font-size: 4.5rem;

      &.smaller {
        font-size: 3.6rem;
      }

      span.second {
        font-size: 1.5rem;
      }
    }

    p.date {
      font-size: 1.25rem;
    }
  }
`

const ControlPanel = styled.div`
  width: 100%;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`

const StyledButton = styled(Button)`
  &.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    width: 4rem;
    height: 4rem;
    ${({ set_theme, set_color }) =>
      set_color
        ? `
      border: 1px solid ${hexToRgbaColor(
        getSiteColor(set_color, set_theme),
        0.5
      )};
      color: ${getSiteColor(set_color, set_theme)};
    `
        : `
      border: 1px solid ${
        set_theme === `light`
          ? `rgba(0, 0, 0, 0.5)`
          : `rgba(255, 255, 255, 0.5)`
      };
      color: ${set_theme === `light` ? `#000` : `#fff`};
    `}
    border-radius: 100%;
    transition: 0.3s;

    &.Mui-disabled {
      ${({ set_theme }) => `
        border: 1px solid ${
          set_theme === `light`
            ? `rgba(0, 0, 0, 0.12)`
            : `rgba(255, 255, 255, 0.12)`
        };
        color: ${
          set_theme === `light`
            ? `rgba(0, 0, 0, 0.5)`
            : `rgba(255, 255, 255, 0.5)`
        };
      `}
    }
  }
`

const Report = styled.div`
  margin-top: 2rem;
  margin-bottom: 3rem;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column-reverse;
  border-bottom: 1px solid
    ${({ theme }) =>
      theme === `light` ? `rgba(0, 0, 0, 0.12)` : `rgba(255, 255, 255, 0.12)`};
  transition: 0.3s;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 3rem;
    border-top: 1px solid
      ${({ theme }) =>
        theme === `light`
          ? `rgba(0, 0, 0, 0.12)`
          : `rgba(255, 255, 255, 0.12)`};
    transition: 0.3s;

    span {
      font-size: 1.5rem;
      color: ${({ theme }) => (theme === `light` ? `#000` : `#fff`)};
      transition: 0.3s;
    }
  }

  @media (max-width: 991px) {
    div {
      span {
        font-size: 1.25rem;
      }
    }
  }
`

const StopWatch = () => {
  const { lang, theme } = useSelector(({ mainReducer }) => mainReducer)
  const dispatch = useDispatch()
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)
  const [passedTime, setPassedTime] = useState(0)
  const [timer, setTimer] = useState({
    hr: `0`,
    min: `00`,
    sec: `00`,
    millisec: `00`,
  })
  const [lapStartTime, setLapStartTime] = useState(0)
  const [lapEndTime, setLapEndTime] = useState(0)
  const [lapPassedTime, setLapPassedTime] = useState(0)
  const [lapTimer, setLapTimer] = useState({
    hr: `0`,
    min: `00`,
    sec: `00`,
    millisec: `00`,
  })
  const [laps, setLaps] = useState([])

  useEffect(() => {
    dispatch(
      setCurrentPage({
        page: `stopwatch`,
        group: `app`,
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (currentTime > startTime) {
      const getCurrentTime = currentTime - startTime + passedTime
      const hr = Math.floor(getCurrentTime / 1000 / 60 / 60)
      const min = Math.floor((getCurrentTime / 1000 / 60) % 60)
      const sec = Math.floor((getCurrentTime / 1000) % 60)
      const millisec = Math.floor((getCurrentTime % 1000) / 10)

      setTimer({
        hr: `${hr}`,
        min: min < 10 ? `0${min}` : min,
        sec: sec < 10 ? `0${sec}` : sec,
        millisec: millisec < 10 ? `0${millisec}` : `${millisec}`,
      })
    }
  }, [currentTime, startTime, passedTime])

  useEffect(() => {
    if (currentTime > lapStartTime) {
      const getLapCurrentTime = currentTime - lapStartTime + lapPassedTime
      const lapHr = Math.floor(getLapCurrentTime / 1000 / 60 / 60)
      const lapMin = Math.floor((getLapCurrentTime / 1000 / 60) % 60)
      const lapSec = Math.floor((getLapCurrentTime / 1000) % 60)
      const lapMillisec = Math.floor((getLapCurrentTime % 1000) / 10)

      setLapTimer({
        hr: `${lapHr}`,
        min: lapMin < 10 ? `0${lapMin}` : lapMin,
        sec: lapSec < 10 ? `0${lapSec}` : lapSec,
        millisec: lapMillisec < 10 ? `0${lapMillisec}` : `${lapMillisec}`,
      })
    }
  }, [currentTime, lapStartTime, lapPassedTime])

  useEffect(() => {
    if (laps.length > 0) {
      const dateString = new Date()

      setLapStartTime(dateString.getTime())
      setLapEndTime(0)
      setLapPassedTime(0)
      setLapTimer({
        hr: `0`,
        min: `00`,
        sec: `00`,
        millisec: `00`,
      })
    }
  }, [laps])

  useInterval(
    () => {
      fetchTime()
    },
    isPlaying ? 10 : null
  )

  const triggerStopWatch = () => {
    const dateString = new Date()

    if (isPlaying === false) {
      if (startTime === 0) {
        setStartTime(dateString.getTime())
      } else {
        setPassedTime(prevPassedTime => endTime - startTime + prevPassedTime)
        setStartTime(dateString.getTime())
        setEndTime(0)
      }

      if (lapStartTime === 0) {
        setLapStartTime(dateString.getTime())
      } else {
        setLapPassedTime(
          prevLapPassedTime => lapEndTime - lapStartTime + prevLapPassedTime
        )
        setLapStartTime(dateString.getTime())
        setLapEndTime(0)
      }
    } else {
      setEndTime(dateString.getTime())
      setLapEndTime(dateString.getTime())
    }

    setIsPlaying(!isPlaying)
  }

  const fetchTime = () => {
    const dateString = new Date()

    setCurrentTime(dateString.getTime())
  }

  const backToinitialState = () => {
    setCurrentTime(0)
    setStartTime(0)
    setEndTime(0)
    setPassedTime(0)
    setTimer({
      hr: `0`,
      min: `00`,
      sec: `00`,
      millisec: `00`,
    })
    setLapStartTime(0)
    setLapEndTime(0)
    setLapPassedTime(0)
    setLapTimer({
      hr: `0`,
      min: `00`,
      sec: `00`,
      millisec: `00`,
    })
    setLaps([])
  }

  const saveLaps = () => {
    const lapResult = `${lapTimer.hr !== `0` ? `${lapTimer.hr}:` : ``}${
      lapTimer.min
    }:${lapTimer.sec}:${lapTimer.millisec}`

    setLaps([
      ...laps,
      {
        id: laps.length + 1,
        time: lapResult,
      },
    ])
  }

  return (
    <Layout>
      <Seo title={stopwatch.pageName[lang]} />
      <Section title={stopwatch.title[lang]} icon={<FaStopwatch />}>
        <Flex className="center__center">
          <ClockContent>
            <div className={`time number ${timer.hr !== `0` ? `smaller` : ``}`}>
              <span>
                {timer.hr !== `0` && `${timer.hr}:`}
                {timer.min}:{timer.sec}
              </span>
              <span className="second">.{timer.millisec}</span>
            </div>
            <ControlPanel>
              {startTime > 0 ? (
                isPlaying ? (
                  <StyledButton
                    aria-label="lap"
                    color="inherit"
                    onClick={() => {
                      saveLaps()
                      dispatch(playSound(`confirm`))
                    }}
                    set_theme={theme}
                    set_color={`blue`}
                  >
                    {stopwatch.button.lab[lang]}
                  </StyledButton>
                ) : (
                  <StyledButton
                    aria-label="reset"
                    color="inherit"
                    onClick={() => {
                      backToinitialState()
                      dispatch(playSound(`confirm`))
                    }}
                    set_theme={theme}
                    set_color={`grey`}
                  >
                    {stopwatch.button.reset[lang]}
                  </StyledButton>
                )
              ) : (
                <StyledButton color="inherit" disabled set_theme={theme}>
                  {stopwatch.button.lab[lang]}
                </StyledButton>
              )}

              <StyledButton
                aria-label="trigger-stopwatch"
                color="inherit"
                onClick={() => {
                  triggerStopWatch()
                  dispatch(playSound(`confirm`))
                }}
                set_theme={theme}
                set_color={!isPlaying ? `green` : `red`}
              >
                {!isPlaying
                  ? stopwatch.button.start[lang]
                  : stopwatch.button.pause[lang]}
              </StyledButton>
            </ControlPanel>
          </ClockContent>
        </Flex>
        <Flex className="center__center">
          <Report theme={theme}>
            {laps.map((lap, lapIndex) => (
              <div key={lapIndex}>
                <span>
                  {stopwatch.laps[lang]} {lap.id}
                </span>
                <span className="number">{lap.time}</span>
              </div>
            ))}
            <div>
              <span>
                {stopwatch.laps[lang]} {laps.length + 1}
              </span>
              <span className="number">
                {lapTimer.hr !== `0` && `${lapTimer.hr}:`}
                {lapTimer.min}:{lapTimer.sec}.{lapTimer.millisec}
              </span>
            </div>
          </Report>
        </Flex>
      </Section>
    </Layout>
  )
}

export default StopWatch
